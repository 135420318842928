import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { UserAccountContext } from "../../context/userAccountContext";
import Image from "../commons/Image";
import Background from "./Background";

interface MenuItem {
  primary: string;
  to: string;
}

const pages: MenuItem[] = [
  {
    primary: "Hem",
    to: "/",
  },
];

const settings: MenuItem[] = [
  { primary: "Profil", to: "/profil" },
  { primary: "Logga ut", to: "/logga-ut" },
];

interface ListItemLinkProps {
  pathname: string;
  primary: string;
  to: string;
  icon?: React.ReactElement;
}

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
  itemProps,
  ref
) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, pathname } = props;
  return (
    <ListItemButton
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100px",
        textAlign: "center",
      }}
      selected={pathname === to}
      component={Link}
      to={to}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItemButton>
  );
}

function ListItemLinkDropdown(props: ListItemLinkProps) {
  const { icon, primary, to, pathname } = props;
  return (
    <ListItemButton
      sx={{
        display: "flex",
        maxWidth: "100px",
        textAlign: "left",
      }}
      selected={pathname === to}
      component={Link}
      to={to}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItemButton>
  );
}

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const { companyAccount } = useContext(UserAccountContext);

  const location = useLocation();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Background>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
              <Image
                height="50px"
                maxHeight="50px"
                margin="0"
                padding="5px"
                maxWidth="auto"
                objectFit="contain"
                width="auto"
                src="https://firebasestorage.googleapis.com/v0/b/praoplats-2c830.appspot.com/o/praoplats--logo-white.png?alt=media&token=bcab4744-fb15-48e2-b69b-b473307365ad"
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {[...pages, ...settings].map((page) => (
                  <ListItemLinkDropdown
                    key={page.primary}
                    pathname={location.pathname}
                    primary={page.primary}
                    to={page.to}
                  />
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Praoplats
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <ListItemLink
                  key={page.primary}
                  primary={page.primary}
                  to={page.to}
                  pathname={location.pathname}
                />
              ))}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              {settings.map((page) => (
                <ListItemLink
                  key={page.primary}
                  primary={page.primary}
                  to={page.to}
                  pathname={location.pathname}
                />
              ))}
            </Box>
          </Toolbar>
        </Container>
      </Background>
    </AppBar>
  );
}
export default NavBar;
