import React, { ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOverlay } from "../../commons/LoadingOverlay";
import { auth } from "../../../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AccountCreationContext } from "../../../context/accountCreationContext";

interface Props {
  children: ReactNode;
}

export const PublicOnlyRoute = ({ children }: Props) => {
  const { accountCreationLoading } = useContext(AccountCreationContext);
  const [user, error] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (user && accountCreationLoading === false) {
      navigate("/");
    }
  }, [user, accountCreationLoading]);

  return <>{children}</>;
};
