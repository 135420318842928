import { ReactNode, createContext, useState } from "react";

interface Props {
  children: ReactNode;
}

interface AccountCreationContextType {
  accountCreationLoading: boolean;
  setAccountCreationLoading: (accountCreation: boolean) => void;
}

const defaultContext: AccountCreationContextType = {
  accountCreationLoading: false,
  setAccountCreationLoading: (accountCreation: boolean) => {},
};

export const AccountCreationContext =
  createContext<AccountCreationContextType>(defaultContext);

const AccountCreationProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const [accountCreationLoading, setAccountCreationLoading] =
    useState<boolean>(false);

  return (
    <AccountCreationContext.Provider
      value={{
        accountCreationLoading,
        setAccountCreationLoading,
      }}
    >
      {children}
    </AccountCreationContext.Provider>
  );
};

export default AccountCreationProvider;
