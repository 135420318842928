export const isValidEmail = (email: string) => {
  const regex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regex.test(String(email));
};

export const asString = (value: any): string => {
  if (typeof value === "string") {
    return value;
  }
  throw new Error("Not a string.");
};

export const asNumber = (value: any): number => {
  if (typeof value === "number") {
    return value;
  }
  throw new Error("Not a number.");
};

export const asArrayOfStrings = (value: any): string[] => {
  if (Array.isArray(value)) {
    if (value.every((val) => typeof val === "string")) {
      return value;
    }
  }
  throw new Error("Not a string.");
};

export const isValidLuhnAlgorithm = (number: string) => {
  const cleanedNumber = number.replace(/\D/g, "");
  const digits = Array.from(cleanedNumber, Number);

  for (let i = digits.length - 2; i >= 0; i -= 2) {
    let doubledDigit = digits[i] * 2;

    if (doubledDigit > 9) {
      doubledDigit = doubledDigit - 9;
    }

    digits[i] = doubledDigit;
  }

  const sum = digits.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return sum % 10 === 0;
};

export const isValidOrganiationNumber = (organizationsnummer: string) => {
  const cleanedNumber = organizationsnummer.replace(/-/g, "");
  return isValidLuhnAlgorithm(cleanedNumber) && cleanedNumber.length === 10;
};
