import { Box } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;
  height?: string;
  minHeight?: string;
}

const Background = ({ children, height, minHeight }: Props) => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(35deg, rgb(6, 0, 151) 0%, rgb(130, 4, 255) 73%, rgb(193, 15, 255) 100%)",
        minHeight: minHeight ?? "auto",
        height: height ?? "auto",
        padding: "20px",
      }}
    >
      {children}
    </Box>
  );
};

export default Background;
