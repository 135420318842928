import React, { ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../services/firebase";
import { LoadingOverlay } from "../../commons/LoadingOverlay";
import { useAuthState } from "react-firebase-hooks/auth";
import { AccountCreationContext } from "../../../context/accountCreationContext";

interface Props {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: Props) => {
  const [user, loading, error] = useAuthState(auth);
  const { accountCreationLoading } = useContext(AccountCreationContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      user === null &&
      loading === false &&
      accountCreationLoading === false
    ) {
      navigate("/logga-in");
    }
  }, [user, loading, accountCreationLoading]);

  return <>{loading || user === undefined ? null : children}</>;
};
