import React from "react";
import ContentAreaWrapper from "../components/base/ContentAreaWrapper";
import CompanyProfile from "../components/profile/CompanyProfile";
import { useUserAccount } from "../hooks/useUserAccount";

const ProfileScreen = () => {
  const { companyAccount } = useUserAccount();
  return (
    <ContentAreaWrapper>
      {companyAccount && <CompanyProfile companyProfile={companyAccount} />}
    </ContentAreaWrapper>
  );
};

export default ProfileScreen;
