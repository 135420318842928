import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { UserAccountContext } from "../context/userAccountContext";
import { useHeaders } from "./useHeaders";
import { auth } from "../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AccountCreationContext } from "../context/accountCreationContext";

export const useUserAccount = () => {
  const [user] = useAuthState(auth);
  const { accountCreationLoading } = useContext(AccountCreationContext);
  const { userAccount, setUserAccount, companyAccount, setCompanyAccount } =
    useContext(UserAccountContext);

  const { baseHeaders } = useHeaders();

  const [errorUserAccount, setError] = useState<Error | null>(null);
  const [loadingUserAccount, setLoading] = useState(false);

  useEffect(() => {
    if (
      baseHeaders !== null &&
      userAccount === undefined &&
      user &&
      accountCreationLoading === false
    ) {
      setLoading(true);
      (async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/companies/${user.uid}`,
            {
              headers: baseHeaders,
            }
          );
          setCompanyAccount(response.data);
        } catch (err) {
          setError(err as Error);
          setLoading(false);
        } finally {
          loadingUserAccount === true ?? setLoading(false);
        }
      })();
    }
    setLoading(false);
  }, [baseHeaders, accountCreationLoading]);

  return { userAccount, companyAccount, errorUserAccount, loadingUserAccount };
};
