import React from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const PrivacyPolicyScreen = () => {
  return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            gap: "15px",
            rowGap: "15px",
            maxWidth: "1000px",
            margin: "auto",
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <Typography variant="h1" sx={{ fontSize: "3em" }}>
            Integritetspolicy för praoplats.se
          </Typography>
          <Typography>
            Denna integritetspolicy förklarar hur Praoplats samlar in, använder
            och skyddar personlig information som samlas in från användare på
            vår webbplats min.praoplats.se. Vi är engagerade i att skydda din
            integritet och följa tillämpliga dataskyddslagar, inklusive EU:s
            allmänna dataskyddsförordning (GDPR).
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            1. Insamling av personlig information
          </Typography>
          <Typography>
            Vi samlar in följande personlig information om eleverna:
          </Typography>
          <List sx={{ listStyleType: "disc", marginLeft: "30px" }}>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>Förnamn</Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>Efternamn</Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>E-post</Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>Bild</Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>Vilken klass och skola de går i</Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>CV</Typography>
            </ListItem>
          </List>
          <Typography>
            Vi samlar också in följande information från företagen:
          </Typography>
          <List sx={{ listStyleType: "disc", marginLeft: "30px" }}>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>Företagsnamn</Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>Orginisatiosnummer</Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>Kontaktpersonens namn</Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>
                Kontaktuppgifter såsom e-postadress och telefonnummer
              </Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>Företagets adress</Typography>
            </ListItem>
          </List>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            2. Användning av personlig information
          </Typography>
          <Typography>
            Vi använder den insamlade personliga informationen för att
            underlätta kopplingen mellan skolor och företag för att elever ska
            kunna få "prao" (praktisk arbetslivserfarenhet) under sin skoltid.
            Informationen används för att matcha elever med lämpliga företag och
            underlätta kommunikationen mellan elever och företag.
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            3. Hantering av cookies
          </Typography>
          <Typography>
            Vi använder cookies för att förbättra användarupplevelsen på vår
            webbplats. En cookie är en liten textfil som lagras på användarens
            dator eller enhet när de besöker vår webbplats. Vi använder cookies
            för att spara användarens id för hänvisaren och för att förbättra
            våra tjänster. Vi använder även Firebase som har sina egna cookies
            och mer information om dessa finns nedan.
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            4. Information från Firebase
          </Typography>
          <Typography>
            Vi använder olika tjänster från Firebase, och nedan finns
            information om hur Firebase hanterar personlig information:
          </Typography>
          <List sx={{ listStyleType: "disc", marginLeft: "30px" }}>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>
                Firebase agerar som en "data processor" enligt GDPR och hanterar
                personlig information på våra användares vägnar.
              </Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>
                Vissa Firebase-tjänster omfattas av Googles Cloud Platform (GCP)
                användarvillkor, inklusive dataskydds- och säkerhetsvillkoren i
                GCP.
              </Typography>
            </ListItem>
            <ListItem
              sx={{ display: "list-item", paddingX: "5px", paddingY: "1px" }}
            >
              <Typography>
                För specifika Firebase-tjänster, såsom “Cloud Functions for
                Firebase”, “Firebase App Check”, “Firebase Authentication“ och
                “Firebase Hosting”, finns separata användarvillkor och
                dataskydds- och säkerhetsvillkor. Ni kan läsa mer om data
                hanteras och med vilket syfte genom att klicka på länken:
                <Link
                  to={"https://firebase.google.com/support/privacy"}
                  target="__blank"
                >
                  https://firebase.google.com/support/privacy
                </Link>
                .
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            5. Skydd av personlig information
          </Typography>
          <Typography>
            Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att
            skydda den personliga informationen vi samlar in och förhindra
            obehörig åtkomst, ändring eller spridning av informationen. Endast
            auktoriserade personer har tillgång till denna information och de är
            skyldiga att hålla den konfidentiell.
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            6. Lagringsperiod
          </Typography>
          <Typography>
            Vi sparar den personliga informationen så länge som det är
            nödvändigt för att uppfylla syftet med insamlingen, samt för att
            följa tillämpliga lagar och regler. När informationen inte längre
            behövs kommer vi att säkerställa att den tas bort på ett säkert
            sätt.
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            7. Dina rättigheter
          </Typography>
          <Typography>
            Enligt GDPR har du vissa rättigheter relaterade till din personliga
            information. Du har rätt att begära åtkomst till, rättelse eller
            radering av din personliga information. Du har även rätt att
            begränsa eller invända mot vår behandling av din personliga
            information, samt att begära att få din personliga information i ett
            strukturerat, maskinläsbart format.
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            8. Kontaktinformation
          </Typography>
          <Typography>
            Om du har några frågor, kommentarer eller begäranden relaterade till
            denna integritetspolicy, vänligen kontakta oss på följande adress:
          </Typography>
          <Typography>
            E-post:{" "}
            <Link to="mailto:hej@praoplats.se" target="__blank">
              hej@praoplats.se
            </Link>
          </Typography>
          <Typography>
            Vi kommer att göra vårt bästa för att svara på dina förfrågningar
            och lösa eventuella klagomål så snart som möjligt.
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            9. Ändringar i integritetspolicyn
          </Typography>
          <Typography>
            Vi förbehåller oss rätten att ändra denna integritetspolicy när som
            helst. Eventuella ändringar kommer att publiceras på vår webbplats
            och träder i kraft omedelbart efter publicering. Vi rekommenderar
            att du regelbundet granskar denna integritetspolicy för att hålla
            dig informerad om eventuella ändringar.
          </Typography>
        </Box>
      </Box>
  );
};

export default PrivacyPolicyScreen;
