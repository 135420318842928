import React, { useContext } from "react";
import { LoadingOverlay } from "../commons/LoadingOverlay";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebase";
import useUser from "../../hooks/useUser";
import useCompany from "../../hooks/useCompany";
import useConsent from "../../hooks/useConsent";
import useSchool from "../../hooks/useSchool";
import { useUserAccount } from "../../hooks/useUserAccount";
import { useHeaders } from "../../hooks/useHeaders";
import { AccountCreationContext } from "../../context/accountCreationContext";
import { UserAccountContext } from "../../context/userAccountContext";

const LoadingState = () => {
  const [user, loadingUserAuth] = useAuthState(auth);
  const { loadingUser } = useUser();
  const { loadingCompany } = useCompany();
  const { loadingConsent } = useConsent();
  const { loadingSchool } = useSchool();
  const { loadingUserAccount } = useUserAccount();
  const { baseHeadersLoading } = useHeaders();
  const { accountCreationLoading } = useContext(AccountCreationContext);
  const { companyAccount, userAccount } = useContext(UserAccountContext);

  const noUser = user !== null && user !== undefined;

  const noUserAccount =
    noUser && companyAccount === undefined && userAccount === undefined;

  const loadingState =
    loadingUserAuth ||
    loadingUser ||
    loadingCompany ||
    loadingConsent ||
    loadingSchool ||
    loadingUserAccount ||
    baseHeadersLoading ||
    accountCreationLoading ||
    noUserAccount;

  return <LoadingOverlay loading={loadingState} />;
};

export default LoadingState;
