import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Image from "../commons/Image";

function HeaderPublicView() {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        onClick={() => navigate("/")}
        sx={{
          cursor: "pointer",
          paddingX: "20px",
          paddingY: "5px",
          marginBottom: "20px",
          width: "200px",
          height: "auto",
        }}
      >
        <Image
          height="50px"
          maxHeight="50px"
          margin="0"
          padding="5px"
          maxWidth="auto"
          objectFit="contain"
          width="auto"
          src="https://firebasestorage.googleapis.com/v0/b/praoplats-2c830.appspot.com/o/praoplats--logo-white.png?alt=media&token=bcab4744-fb15-48e2-b69b-b473307365ad"
        />
      </Box>
    </Box>
  );
}

export default HeaderPublicView;
