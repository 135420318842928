import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import React, { ChangeEvent, useContext, useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../services/firebase";
import { UserCredential, sendEmailVerification } from "firebase/auth";
import useCompany from "../hooks/useCompany";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from "@mui/material";
import useConsent from "../hooks/useConsent";
import { LoadingOverlay } from "../components/commons/LoadingOverlay";
import { Hashtag } from "../data/hashtags";
import EmailTextField from "../components/commons/EmailTextField";
import PasswordTextField from "../components/commons/PasswordTextField";
import PostalCodeTextField from "../components/commons/PostalCodeTextField";
import RequiredTextField from "../components/commons/RequiredTextField";
import RequiredTextArea from "../components/commons/RequiredTextArea";
import OrganizationNumberField from "../components/commons/OrganizationNumberField";
import PhoneNumberTextField from "../components/commons/PhoneNumberField";
import { Option } from "../types/common";
import KommunAutoCompleteField from "../components/commons/KommunAutoCompleteField";
import HashtagPicker from "../components/commons/HashtagPicker";
import HashtagListEdit from "../components/commons/HashtagListEdit";
import { AuthErrorCode, mapAuthErrorCodeToText } from "../utils/firebaseAuth";
import FileUploader from "../components/commons/FileUploader";
import { firebaseStore } from "../config/FirebaseConfig";
import { UploadingOverlay } from "../components/commons/UploadingOverlay";
import { AccountCreationContext } from "../context/accountCreationContext";

const RegisterCompanyScreen = () => {
  const { postCompany, errorCompany } = useCompany();
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  const { consent, postCosent, setConsent, loadingConsent, consentFetchError } =
    useConsent();

  const { setAccountCreationLoading } = useContext(AccountCreationContext);

  const [runValidation, setRunValidation] = useState<boolean | "true">(false);

  const [email, setEmail] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [praoPositions, setPraoPositions] = useState("");
  const [industry, setIndustry] = useState("");
  const [about, setAbout] = useState("");
  const [praoTasksDescription, setPraoTasksDescription] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [password, setPassword] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [selectedKommun, setSelectedKommun] = useState<Option<string> | null>(
    null
  );
  const [selectedHashtag, setSelectedHashtag] =
    useState<Option<Hashtag> | null>(null);
  const [allSelectedHashtags, setAllSelectedHashtags] = useState<
    Option<Hashtag>[]
  >([]);
  const [praoPositionsError, setPraoPositionsError] = useState<null | string>(
    null
  );
  const [industryError, setIndustryError] = useState<null | string>(null);
  const [contactPersonFirstName, setContactPersonFirstName] = useState("");
  const [contactPersonLastName, setContactPersonLastName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [organizationNumber, setOrganizationNumber] = useState("");
  const [policyBoxChecked, setPolicyBoxChecked] = useState(false);
  const [policyBoxCheckedError, setPolicyBoxCheckedError] = useState<
    null | string
  >(null);
  const [arbetsmiljoverketBoxChecked, setArbetsmiljoverketBoxChecked] =
    useState(false);
  const [
    arbetsmiljoverketBoxCheckedError,
    setArbetsmiljoverketBoxCheckedError,
  ] = useState<null | string>(null);

  const [emailError, setEmailError] = useState<null | string>(null);
  const [passwordError, setPasswordError] = useState<null | string>(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<
    null | string
  >(null);
  const [companyNameError, setCompanyNameError] = useState<null | string>(null);
  const [aboutError, setAboutError] = useState<null | string>(null);
  const [praoTasksDescriptionError, setPraoTasksDescriptionError] = useState<
    null | string
  >(null);
  const [streetError, setStreetError] = useState<null | string>(null);
  const [cityError, setCityError] = useState<null | string>(null);
  const [contactEmailError, setContactEmailError] = useState<null | string>(
    null
  );
  const [postalCodeError, setPostalCodeError] = useState<null | string>(null);
  const [kommunError, setKommunError] = useState<null | string>(null);
  const [contactPersonFirstNameError, setContactPersonFirstNameError] =
    useState<null | string>(null);
  const [contactPersonLastNameError, setContactPersonLastNameError] = useState<
    null | string
  >(null);
  const [contactPhoneError, setContactPhoneError] = useState<null | string>(
    null
  );
  const [organizationNumberError, setOrganizationNumberError] = useState<
    null | string
  >(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [percentageLogoUpload, setPercentageLogoUpload] = useState<
    null | number
  >(null);
  const [logoUploadError, setLogoUploadError] = useState<null | string>(null);

  const navigate = useNavigate();

  const triggerRunValidation = () => {
    if (runValidation === true) {
      setRunValidation("true");
    }
    if (runValidation === false) {
      setRunValidation(true);
    }
    if (runValidation === "true") {
      setRunValidation(true);
    }
  };

  const validatePraoPositions = (praoPositions: string) => {
    const isValid = praoPositions.trim() !== "";
    if (isValid === false) {
      setPraoPositionsError(
        "Du måste ange hur många praoelever ni kan ta emot."
      );
    } else {
      setPraoPositionsError(null);
    }
    return isValid;
  };

  const validateIndustry = (industry: string) => {
    const isValid = industry.length > 0;
    if (isValid === false) {
      setIndustryError("Du måste ange en bransch.");
    } else {
      setIndustryError(null);
    }
    return isValid;
  };

  const handlePraoPositionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (praoPositionsError !== null) {
      setPraoPositionsError(null);
    }
    const { value } = event.target;
    setPraoPositions(value);
  };

  const handlePolicyCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (policyBoxCheckedError !== null) {
      setPolicyBoxCheckedError(null);
    }
    setPolicyBoxChecked(event.target.checked);
  };

  const handleArbetsmiljoverketCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (policyBoxCheckedError !== null) {
      setArbetsmiljoverketBoxCheckedError(null);
    }
    setArbetsmiljoverketBoxChecked(event.target.checked);
  };

  const handleIndustryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (industryError !== null) {
      setIndustryError(null);
    }
    const { value } = event.target;
    setIndustry(value);
  };

  const validatePolicyBox = () => {
    if (policyBoxChecked === false) {
      setPolicyBoxCheckedError(
        "Du måste godkänna integritetspolicyn för att skapa ett konto."
      );
    }
    return policyBoxChecked;
  };

  const validateArbetsmiljoverketBox = () => {
    if (arbetsmiljoverketBoxChecked === false) {
      setArbetsmiljoverketBoxCheckedError(
        "Du måste godkänna att du läst och kommer att följa arbetsmiljöverkets regler och föreskriver."
      );
    }
    return arbetsmiljoverketBoxChecked;
  };

  const runCreateCompany = async (user: UserCredential, logoUrl?: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/foretag/epost-verifierad`;
    await sendEmailVerification(user.user, {
      url,
    });
    const token = await user.user.getIdToken();
    await postCosent();
    await postCompany(
      {
        id: user.user.uid,
        name: companyName,
        organizationNumber: serializeOrganizationNumber(organizationNumber),
        contactPersonFirstName: contactPersonFirstName,
        contactPersonLastName: contactPersonLastName,
        contactEmail: contactEmail,
        contactPhone: serializePhoneNumber(contactPhone),
        postalCode: serializePostalCode(postalCode),
        postalTown: city,
        municipality: selectedKommun!.id,
        street: street,
        about: about,
        praoTasksDescription: praoTasksDescription,
        industry: industry,
        numberOfPraoPositions: parseInt(praoPositions),
        // hashtags: serializeHashtags(allSelectedHashtags),
        logoUrl: logoUrl ? logoUrl : undefined,
      },
      token
    );
    setAccountCreationLoading(false);
    navigate("/foretag/tack");
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const validIndustry = validateIndustry(industry);
    const validPraoPositions = validatePraoPositions(praoPositions);
    const validPolicyBox = validatePolicyBox();
    const validArbetsmiljoBox = validateArbetsmiljoverketBox();

    triggerRunValidation();

    if (
      validIndustry === false ||
      validPraoPositions === false ||
      validPolicyBox === false ||
      validArbetsmiljoBox === false ||
      policyBoxChecked === false ||
      emailError !== null ||
      passwordError !== null ||
      passwordConfirmationError !== null ||
      companyNameError !== null ||
      aboutError !== null ||
      praoTasksDescriptionError !== null ||
      streetError !== null ||
      cityError !== null ||
      contactEmailError !== null ||
      postalCodeError !== null ||
      kommunError !== null ||
      contactPersonFirstNameError !== null ||
      contactPersonLastNameError !== null ||
      contactPhoneError !== null ||
      organizationNumberError !== null
    ) {
      return;
    }
    setAccountCreationLoading(true);

    const user = await createUserWithEmailAndPassword(email, password);

    if (user) {
      if (logoFile !== null) {
        const logoStorageRef = ref(
          firebaseStore,
          `/company/${user.user.uid}/${logoFile.name}`
        );
        const uploadLogoTask = uploadBytesResumable(logoStorageRef, logoFile);

        uploadLogoTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            setPercentageLogoUpload(percent);
          },
          (err) =>
            setLogoUploadError(
              "Misslyckades att ladda upp logotyp. Försök igen"
            ),
          async () => {
            setPercentageLogoUpload(null);
            const logoUrl = await getDownloadURL(uploadLogoTask.snapshot.ref);
            await runCreateCompany(user, logoUrl);
          }
        );
      } else {
        await runCreateCompany(user);
      }
    }
    setAccountCreationLoading(false);
  };

  function serializeOrganizationNumber(postalCode: string) {
    return postalCode.replace(/[-]/g, "");
  }

  function serializePostalCode(postalCode: string) {
    return postalCode.replace(/[ ]/g, "");
  }

  function serializePhoneNumber(phone: string) {
    const withoutDashesAndSpaces = phone.replace(/[- ]/g, "");
    return `0046${withoutDashesAndSpaces}`;
  }

  // function serializeHashtags(hashtags: Option<Hashtag>[]) {
  //   return hashtags.map((hashtag) => hashtag.label);
  // }

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <LoadingOverlay loading={loadingConsent || loading} />
      <UploadingOverlay
        percentage={percentageLogoUpload}
        label="Laddar upp logotyp"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          gap: "15px",
          rowGap: "15px",
          maxWidth: "800px",
          margin: "auto",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <Typography variant="h2" fontSize={"2em"}>
          Registrera ditt företag
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{ fontSize: "1rem", fontWeight: 600, fontStyle: "italic" }}
          >
            ”Att ta emot en praoelev är inte bara roligt − du och din
            arbetsplats gör även en stor insats.""
          </Typography>
          <Typography sx={{ fontSize: "1rem" }}>
            Din arbetsplats kan ge en ung människa chansen att kanske för första
            gången se hur arbetslivet fungerar, samtidigt som ni kan få nya
            perspektiv på verksamheten.
          </Typography>
        </Box>
        <Typography variant="h3" fontSize={"1.4em"}>
          Inlogginsuppgifter
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <EmailTextField
              email={email}
              setEmail={setEmail}
              label="Epost för inlogg"
              runValidation={runValidation}
              emailError={emailError}
              setEmailError={setEmailError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordTextField
              password={password}
              setPassword={setPassword}
              label="Lösenord"
              name="passowrd"
              runValidation={runValidation}
              passwordError={passwordError}
              setPasswordError={setPasswordError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PasswordTextField
              password={passwordConfirmation}
              setPassword={setPasswordConfirmation}
              label="Upprepa lösenord"
              name="confirmPassword"
              runValidation={runValidation}
              passwordError={passwordConfirmationError}
              setPasswordError={setPasswordConfirmationError}
            />
          </Grid>
        </Grid>
        <Typography variant="h3" fontSize={"1.4em"}>
          Om företaget
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="body1">
              Ladda upp företagets logotyp
            </Typography>
            <FileUploader
              selectedFile={logoFile}
              setSelectedFile={setLogoFile}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RequiredTextField
              value={companyName}
              setValue={setCompanyName}
              label="Företagsnamn"
              name="Företagsnamn"
              runValidation={runValidation}
              helperText="Du måste ange ett företagsnamn."
              valueError={companyNameError}
              setValueError={setCompanyNameError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OrganizationNumberField
              organizationNumber={organizationNumber}
              setOrganizationNumber={setOrganizationNumber}
              label="Organisationsnummer"
              name="organization-number"
              runValidation={runValidation}
              organizationNumberError={organizationNumberError}
              setOrganizationNumberError={setOrganizationNumberError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Bransch"
              name="industry"
              value={industry}
              onChange={handleIndustryChange}
              error={!!industryError}
              helperText={industryError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Tillgängliga praoplatser per termin"
              name="number-of-prao-positions"
              type="number"
              value={praoPositions ?? ""}
              onChange={handlePraoPositionsChange}
              error={!!praoPositionsError}
              helperText={praoPositionsError}
              inputProps={{
                min: 0,
                step: 1,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <RequiredTextArea
              value={about}
              setValue={setAbout}
              label="Kortfattat om vad ni gör på företaget"
              name="about"
              helperText="Du måste ange en kort beskrivning på minst 10 tecken av företaget."
              minLength={10}
              runValidation={runValidation}
              valueError={aboutError}
              setValueError={setAboutError}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <RequiredTextArea
              value={praoTasksDescription}
              setValue={setPraoTasksDescription}
              label="Vilka arbetsuppgifter kommer en praoelev få göra hos er?"
              name="prao-tasks-description"
              helperText="Du måste ange en kort beskrivning på minst 10 tecken av vad praoeleverna kommer att göra hos er."
              minLength={10}
              runValidation={runValidation}
              valueError={praoTasksDescriptionError}
              setValueError={setPraoTasksDescriptionError}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <Typography variant="h3" fontSize={"1.4em"}>
              Hjälp elever matcha med er genom att välja relevanta #hashtags.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <HashtagPicker
              selectedHashtag={selectedHashtag}
              setSelectedHashtag={setSelectedHashtag}
              allSelectedHashtags={allSelectedHashtags}
              setAllSelectedHashtags={setAllSelectedHashtags}
              label="Sök hashtag"
              name="hashtag"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <HashtagListEdit
              hashtags={allSelectedHashtags}
              setHashtags={setAllSelectedHashtags}
            />
          </Grid> */}
        </Grid>
        <Typography variant="h3" fontSize={"1.4em"}>
          Kontaktinformation
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <RequiredTextField
              value={contactPersonFirstName}
              setValue={setContactPersonFirstName}
              label="Förnamn på kontaktperson"
              name="contact-person-first-name"
              helperText="Du måste ange ett förnamn."
              runValidation={runValidation}
              valueError={contactPersonFirstNameError}
              setValueError={setContactPersonFirstNameError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RequiredTextField
              value={contactPersonLastName}
              setValue={setContactPersonLastName}
              label="Efternamn på kontaktperson"
              name="contact-person-last-name"
              helperText="Du måste ange ett efternamn."
              runValidation={runValidation}
              valueError={contactPersonLastNameError}
              setValueError={setContactPersonLastNameError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EmailTextField
              email={contactEmail}
              setEmail={setContactEmail}
              label="Epost för kontakt"
              runValidation={runValidation}
              emailError={contactEmailError}
              setEmailError={setContactEmailError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneNumberTextField
              phoneNumber={contactPhone}
              setPhoneNumber={setContactPhone}
              label="Telefon för kontakt"
              name="contact-phone"
              runValidation={runValidation}
              phoneNumberError={contactPhoneError}
              setPhoneNumberError={setContactPhoneError}
            />
          </Grid>
        </Grid>
        <Typography variant="h3" fontSize={"1.4em"}>
          Adress
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <KommunAutoCompleteField
              selectedKommun={selectedKommun}
              setSelectedKommun={setSelectedKommun}
              label="Kommun"
              name="kommun"
              runValidation={runValidation}
              kommunError={kommunError}
              setKommunError={setKommunError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PostalCodeTextField
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              label="Postnummer"
              runValidation={runValidation}
              postalCodeError={postalCodeError}
              setPostalCodeError={setPostalCodeError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RequiredTextField
              value={city}
              setValue={setCity}
              label="Postort"
              name="city"
              helperText="Du måste ange en postort."
              runValidation={runValidation}
              valueError={cityError}
              setValueError={setCityError}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RequiredTextField
              value={street}
              setValue={setStreet}
              label="Gata"
              name="street"
              helperText="Du måste ange en gata."
              runValidation={runValidation}
              valueError={streetError}
              setValueError={setStreetError}
            />
          </Grid>
        </Grid>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={policyBoxChecked}
                onChange={handlePolicyCheckboxChange}
              />
            }
            label={
              <Typography variant="body2" color="textSecondary">
                Jag godkänner{" "}
                <Link to="/integritetspolicy" color="inherit" target="_blank">
                  integritetspolicyn
                </Link>
              </Typography>
            }
          />
          <FormHelperText
            error={!!policyBoxCheckedError}
            sx={{ color: "#d32f2f" }}
          >
            {policyBoxCheckedError}
          </FormHelperText>
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={arbetsmiljoverketBoxChecked}
                onChange={handleArbetsmiljoverketCheckboxChange}
              />
            }
            label={
              <Typography variant="body2" color="textSecondary">
                Jag har läst och kommer följa arbetsmiljöverkets regler och
                föreskrifter{" "}
                <Link
                  to="https://www.av.se/globalassets/filer/publikationer/broschyrer/sa-far-barn-och-ungdomar-arbeta-broschyr-adi043.pdf"
                  color="inherit"
                  target="_blank"
                >
                  Så får barn och ungdomar arbeta.
                </Link>
              </Typography>
            }
          />
          <FormHelperText
            error={!!arbetsmiljoverketBoxCheckedError}
            sx={{ color: "#d32f2f" }}
          >
            {arbetsmiljoverketBoxCheckedError}
          </FormHelperText>
        </FormGroup>
        {error && (
          <Typography sx={{ color: "#d32f2f" }}>
            {mapAuthErrorCodeToText(error.code as AuthErrorCode)}
          </Typography>
        )}
        {logoUploadError && (
          <Typography sx={{ color: "#d32f2f" }}>{logoUploadError}</Typography>
        )}
        <Button
          sx={{ flex: 1 }}
          onClick={handleSubmit}
          type="submit"
          variant="contained"
        >
          Registrera företaget
        </Button>
        <Typography>
          Har du redan ett konto för företag?{" "}
          <Link to={"/logga-in"}>Klicka här för att logga in</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default RegisterCompanyScreen;
